var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.brandBotConfig)?_c('a-card',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('brand_bot_settings_title'))}}),_c('div',[_c('editor-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': this,
          'key': 'hellow',
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'hasAnchor': true,
        }
      }}})],1),_c('div',{staticClass:"flex flex-col xl:flex-row justify-end"},[_c('a-button',{staticClass:"btn-success mr-0 xl:mr-2",attrs:{"icon":"save"},on:{"click":_vm.saveBrandConfig}},[_vm._v(" "+_vm._s(_vm.$t("save_settings"))+" ")]),_c('a-button',{staticClass:"mt-2 xl:mt-0",attrs:{"type":"danger"},on:{"click":_vm.resetBrandingBot}},[_vm._v(" "+_vm._s(_vm.$t("reset_bot_branding"))+" ")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }