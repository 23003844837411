








































































































import { InputSetups } from "@/mixins/input-setups";
import { errorNotification } from '@/includes/NotificationService'

import { UseFields } from "piramis-base-components/src/components/Pi/index";
import SelectPopup from "piramis-base-components/src/components/SelectPopup/SelectPopup.vue"

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    SelectPopup
  }
})
export default class BrandBotSettings extends Mixins(UseFields, InputSetups) {
  get brandBotConfig(): any {
    return this.$store.state.chatState.brandConfig
  }

  get tokenHash(): string {
    return this.$store.state.chatState.chat.brand.token_hash
  }

  get hellow(): string {
    return this.brandBotConfig.hellow
  }

  set hellow(value: string) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.brandBotConfig.hellow = value
      }
    })
  }

  // get flow(): string {
  //   return this.brandBotConfig.flow
  // }
  //
  // set flow(value: any) {
  //   this.$store.commit('pi/EXEC', {
  //     'fn': () => {
  //       this.brandBotConfig.flow = value
  //     }
  //   })
  // }

  activeBrand(): number {
    if (this.brandBotConfig.flow) {
      return 1
    } else {
      return 0
    }
  }

  showPopup = false

  // flowsListForPopup: any = []

  // getFlowSelectPlaceholder(): string {
  //   return this.flowsListForPopup.length > 0 ? '' : 'Нет ни одного флоу'
  // }

  // checkFlowsList(): boolean {
  //   return this.flowsListForPopup.length <= 0
  // }

  // goToCreateNewFlow(): void {
  //   this.showPopup = false
  //   setTimeout(() => {
  //     this.$router.push({
  //       name: 'flows_list',
  //       params: {
  //         boardId: this.$store.state.chatState.chat.post_board.tokenId
  //       }
  //     })
  //   }, 30)
  // }
  //
  // goToEditFlow(id: string): void {
  //   this.showPopup = false
  //   setTimeout(() => {
  //     this.$router.push({
  //       name: 'flow',
  //       params: {
  //         boardId: this.$store.state.chatState.chat.post_board.tokenId,
  //         actionType: 'edit'
  //       },
  //       query: {flowKey: id}
  //     })
  //   }, 30)
  // }
  //
  // selectFlow(flow: any) {
  //   this.$store.dispatch('swapBrandFlow', flow)
  //   this.showPopup = false
  // }
  //
  // swapBrandConfig(param: string): void {
  //   this.$store.dispatch('swapChatBrand', param)
  // }

  resetBrandingBot(): void {
    this.$confirm({
      title: this.$t("dialog_reset_chat_brand_title").toString(),
      content: this.$t("dialog_reset_chat_brand_text").toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: async (): Promise<void> => {
        try {
          await this.$store.dispatch('resetChatBrand')
        } catch (error) {
          errorNotification(error)
        }
      }
    })
  }

  saveBrandConfig(): void {
    this.$confirm({
      title: this.$t("dialog_save_brand_config_title").toString(),
      content: this.$t("dialog_save_brand_config_text").toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.$store.dispatch('saveBrandConfig', { token_hash: this.tokenHash })
      }
    })
  }

  async created(): Promise<void> {
    try {
      await this.$store.dispatch("getBrandConfig", { token_hash: this.tokenHash })
      // await this.$store.dispatch('requestGetFlows', `${this.$store.state.chatState.chat.post_board.tokenId}.${this.$store.state.chatState.chat.post_board.tokenCode}`)
      //
      // this.$store.state.flows.flows.forEach((flow: any) => {
      //   let flowForPopup = {
      //     title: flow.title,
      //     id: flow.key,
      //   }
      //   this.flowsListForPopup.push(flowForPopup)
      // })
    } catch (error) {
      console.error(error)
    }
  }

  mounted(): void {
    this.$i18n.mergeLocaleMessage('ru', {
      'brand_bot_settings_title': `<div class="w-full flex font-medium text-lg"><p>Имя бренд-бота:&nbsp;</p><b class="text-primary">${ this.$store.state.chatState.chat.brand.botname }</b></div>`
    })
    this.$i18n.mergeLocaleMessage('en', {
      'brand_bot_settings_title': `<div class="w-full flex font-medium text-lg"><p>Brand bot name:&nbsp;</p><b class="text-primary">${ this.$store.state.chatState.chat.brand.botname }</b></div>`
    })
  }

  beforeDestroy(): void {
    this.$nextTick(() => this.$store.commit("SET_BRAND_BOT_CONFIG", null))
  }
}
